import React, { useState,useEffect,useCallback,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


 const  ConfirmMailControl = ({ConfirmMailCallback,ResendCallBack}) =>{

        
        const [Confirmed, setConfirmed] = useState(false)
        var [state, setState] = useState({mail:"",ConfirmMail:""});
        var isMounted = useRef(true)
        const classes = useStyles();

        const handleChangeMail=(e) =>{
           const Cstate= {...state};
           Cstate.mail=e.target.value;
           setState(Cstate);
        }

        const handleChangeConfirmMail=(e)=> {
            const Cstate= {...state};
            Cstate.ConfirmMail=e.target.value;
            setState(Cstate);
        }

        //Add Custom Validations      
        ValidatorForm.addValidationRule('isMailMatch', (value) => {
            const Cstate= {...state};
            if (value !== state.mail) {
                return false;
            }
            return true;
        });

        useEffect(() =>{ 
          return () => {
            // Remove Custom Validations
            ValidatorForm.removeValidationRule('isMailMatch');
            isMounted=false;
            }
                    }, []);


        const sendMail =()=>{
          setConfirmed(true);
          ConfirmMailCallback(state.mail);
        }

        const ResendMail  =()=>{
          ResendCallBack();
        }

    var Result = {};
      if (!Confirmed){
        Result = <React.Fragment>
               <div className={classes.paper}>
                 <Avatar className={classes.avatar}>
                   <LockOpenIcon />
                 </Avatar>
                 <Typography component="h1" variant="h5">
                   Reset Password
                 </Typography>
                 <ValidatorForm className={classes.form} 
                  onSubmit={sendMail}
                 >
                   <Grid container spacing={2}>
                     <Grid item xs={12}>
                       <TextValidator
                         variant="outlined"
                         fullWidth
                         id="email"
                         label="Email Address"
                         name="email"
                         value={state.mail}
                         validators={['required', 'isEmail']}
                         onChange={handleChangeMail}
                       />
                     </Grid>
                     <Grid item xs={12}>
                       <TextValidator
                         variant="outlined"
                         fullWidth
                         id="Cemail"
                         label="Confirm Email Address"
                         name="Cemail"
                         value={state.ConfirmMail}
                         validators={['required', 'isEmail','isMailMatch']}
                         onChange={handleChangeConfirmMail}
                       />
                     </Grid>
        
                   </Grid>
                   <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                   
                   >
                     Enviar Codigo
                   </Button>
        
                 </ValidatorForm>
               </div>
             </React.Fragment>
      }else{
        Result=
             <React.Fragment>
                      <Grid container>
                        <Grid item xs>
                              <label tyle={{ color: 'gray' }}>Se a enviado un correo con tu codigo de confirmacion  
                              </label>
                              <Link onClick={ResendMail} variant="body2">
                                    Reenviar correo a {state.mail}
                                </Link>
                        </Grid>
                      </Grid>
             </React.Fragment>
      }
  return (Result);
}


export default ConfirmMailControl;
import React from 'react';
import logo from './logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';
import Header from './components/Navigation/Header'
import Footer from './components/Navigation/Footer'
import ResetPassword from './components/User/ResetPassword.jsx'
import { Switch,Route} from 'react-router-dom';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}));



function App() {
  const classes = useStyles();
  return (
    <React.Fragment>
       <CssBaseline />
      <Header></Header>
     <main>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="sm" component="main" className={classes.main} >
          <Switch>
                <Route path="/user/resetPassword" component={ResetPassword} exact />
          </Switch>
          </Container>
          </main>
      <Footer></Footer>
    </React.Fragment>
  );
}

export default App;

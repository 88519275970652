import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';

const drawerWidth = 0;

const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
    },

  }));

function Header() {
    const classes = useStyles();
    return (
        <AppBar position="absolute" className={clsx(classes.appBar,  classes.appBarShift)}>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                Akron Gasolinerias
            </Typography>
        </AppBar>
    );
  }
  
  export default Header;
  
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import ConfirmMailControl from './ConfirmMail'
import ConfirmPasswordControl from './ConfirmPassword'



function ResetPassword() {

  const [isSending, setIsSending] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [Error, setError] = useState("")
  const [state, SetState] = useState({ mail: '' });
  var isMounted = useRef(true)



  const SendCode = useCallback((e) => {
    const newState = { ...state };
    newState.mail = e;
    SetState(newState);
    if (isSending) return
    setIsSending(true);
    SendMail(e);
    if (isMounted.current) // only update if we are still mounted
      setIsSending(false)
  }, [isSending, state]) // update the callback if the state changes

  const ReSendCode = useCallback(() => {
    const newState = { ...state };
    if (isSending) return
    setIsSending(true);
    SendMail(newState.mail);
    if (isMounted.current) // only update if we are still mounted
      setIsSending(false)
  }, [isSending, state]) // update the callback if the state changes

  useEffect(() => {
    return () => {
      isMounted = false;
    }
  }, []);

  ResetPassword = (password, token) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ User: state.mail, NewPassword: password, Token: token })
    };
    fetch('/api/Login/ResetPassword', requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.error == "") {
          setIsComplete(true);
        } else {
          setError(data.error);
        }
      });
  }

  const ResetPasswordCallback = useCallback((password, token) => {
    if (isSending) return
    setIsSending(true);
    ResetPassword(password,token);
    if (isMounted.current) // only update if we are still mounted
      setIsSending(false)
  }, [isSending, state]) // update the callback if the state changes



  function SendMail(mail) {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: mail })
    };
    fetch('/api/Login/ResendConfirmationCode', requestOptions)
      .then(response => response)
      .then(data => { setIsSending(false) });
  }

  var showPass = null;
  if (state.mail != '') {
    showPass = <ConfirmPasswordControl SubmitCallback={ResetPasswordCallback} Error={Error}></ConfirmPasswordControl>;

  }
  var Complete = null;
  if (isComplete) {
    Complete = <React.Fragment><div style={{color:"green"}}><a>Gracias!! tu contraseña a sido actualizada</a></div></React.Fragment>;
  } else {
    Complete = <React.Fragment>
      {showPass}
      <ConfirmMailControl ConfirmMailCallback={SendCode} ResendCallBack={ReSendCode} />
    </React.Fragment>;

  }


  return (
     Complete 
  );
}


export default ResetPassword;
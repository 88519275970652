import React, { useState,useEffect,useCallback,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));
  
  
   const  ConfirmPasswordControl = ({SubmitCallback,Error}) =>{
        var [state, setState] = useState({newPassword:"",ConfirmNewPassword:"",Token:""});    
        var isMounted = useRef(true)
        const classes = useStyles();



        ValidatorForm.addValidationRule('isMailMatch', (value) => {
            const Cstate= {...state};
            if (value !== state.newPassword) {
                return false;
            }
            return true;
        });

        useEffect(() =>{ 
            return () => {
              // Remove Custom Validations
              ValidatorForm.removeValidationRule('isMailMatch');
              isMounted=false;
              }
                      }, []);


        const handleChangenewPassword=(e) =>{
          const Cstate= {...state};
          Cstate.newPassword=e.target.value;
          setState(Cstate);
        }
        
        const handleChangeConfirmnewPassword=(e)=> {
           const Cstate= {...state};
           Cstate.ConfirmNewPassword=e.target.value;
           setState(Cstate);
        }
        

        const handleChangeToken=(e) =>{
            const Cstate= {...state};
            Cstate.Token=e.target.value;
            setState(Cstate);
          }

          const SubmitButton  =()=>{
            SubmitCallback(state.newPassword,state.Token);
          }

          

        return(
            <React.Fragment>
               <div className={classes.paper}>
                 <Avatar className={classes.avatar}>
                   <LockOpenIcon />
                 </Avatar>
                 <Typography component="h1" variant="h5">
                   Reset Password
                 </Typography>
                 <ValidatorForm className={classes.form} 
                    onSubmit={SubmitButton}
                 >
                   <Grid container spacing={2}>
                     <Grid item xs={12}>
                       <TextValidator
                         type="password"
                         variant="outlined"
                         fullWidth
                         label="New Password"
                         value={state.newPassword}
                         validators={['required' ]}
                         onChange={handleChangenewPassword}
                       />
                     </Grid>
                     <Grid item xs={12}>
                       <TextValidator
                         type="password"
                         variant="outlined"
                         fullWidth
                         id="Cemail"
                         label="Confirm New Password"
                         name="Cemail"
                         value={state.ConfirmNewPassword}
                         validators={['required', 'isMailMatch']}
                         onChange={handleChangeConfirmnewPassword}
                       />
                     </Grid>
        

                     <Grid item xs={12}>
                       <TextValidator
                          
                         variant="outlined"
                         fullWidth
                         id="Cemail"
                         label="Confirm Token"
                         name="Cemail"
                         value={state.Token}
                         validators={['required' ]}
                         onChange={handleChangeToken}
                       />
                     </Grid>

                     <Grid item xs={12}>
                       <a style={{color:"red"}}>{Error}</a>
                     </Grid>
                   </Grid>
                   <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     color="primary"
                     className={classes.submit}
                        xs={2}
                   >
                     Actualizar
                   </Button>
        
                 </ValidatorForm>
               </div>
             </React.Fragment>

        );
   }


export default ConfirmPasswordControl;